@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url(./assets/bg.jpg); */
}
/* "https://i.pinimg.com/originals/e4/9a/3d/e49a3d345af2cc85aa174323e7c563cb.jpg" */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* p{
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
} */
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
    font-size: 1.25em;
    font-weight: 700;
    color: black;
    background-color: white;
    display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: white;
}
.inputfile + label {
	cursor: pointer; /* hand cursor */
}
.ptag__remove__paddingAndMargin {
  padding: 0px;
  margin: 0px;
}
.dispFlexRow__alItemCenter__justContSpaceBet{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.basicIcon__lookAndFeel{
  cursor: pointer;
}
.dispFlexCol{
  display: flex;
  flex-direction: column;
}
.dispFlexRow{
  display: flex;
  flex-direction: row;
}
.dispFlexRow__alItemCenter__justContFlexEnd{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.dispFlexRow__alItemCenter__justContCenter{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.dispFlexCol__alItemCenter__justContFlexCenter{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.basicInformation__level1{
  font-size: 18px;
  font-weight: 500;
}
.basicInformation__level2{
  font-size: 16px;
  font-weight: 400;
}